
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { defineComponent } from "vue";
import { Drug } from "@/data/Drug";
import { getDrug } from "../data/repository";
import { capitalize } from "../utils";

export default defineComponent({
  name: "Home",

  data() {
    return {
      drug: {} as Drug,
      getBackButtonText: () => {
        const win = window as any;
        const mode = win && win.Ionic && win.Ionic.mode;
        return mode === "ios" ? "Inbox" : "";
      }
    }
  },

  created() {
    this.loadDrug();
  },

  methods: {
    capitalize,

    async loadDrug() {
      this.drug = await getDrug(useRoute().params.id as string);
    }
  },

  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar
  }
});
