import { groupBy, prettifyArray } from "../utils";

interface Dose {
  age?: string;
  indications?: string[];
  maximum?: string;
  pretty?: string;
  specialConsiderations?: string;
  timing?: string;
  quantity: string;
  route: string;
}

const prettifyDose = (it: Dose) => {
  let dose = `${it.quantity} ${it.route}`;
  if (it.timing) dose += ` ${it.timing}`;
  if (it.specialConsiderations) dose += `; ${it.specialConsiderations}`;
  if (it.maximum) dose += `; max ${it.maximum}`;
  return dose;
}

export class Drug {
  id: string;
  genericName: string;

  adverseEffects: string[];
  contraindications: string[];
  indications: string[];
  doses?: Dose[];

  category: string;
  class: string;
  description?: string;
  duration?: string;
  onset?: string;
  tradeName?: string;
  specialConsiderations?: string[];

  static fromDynamoItem(item: Record<string, any>) {
    item.genericName = item.pk;
    item.class = item.sk;
    const drug = new Drug(item);
    return drug;
  }

  get adultDosesByIndications() {
    const byAge = groupBy(this.doses ?? [], "age");
    return groupBy(byAge.adult, "indications");
  }

  pretty(prop: string): string | undefined {
    const joinStr = " \u2219 ";
    switch (prop) {
      case "adverseEffects":
        return (this.adverseEffects ?? []).join(joinStr);

      case "contraindications":
        return (this.contraindications ?? [])
          .filter((it) => it !== "HS")
          .join(joinStr);
    }
  }

  search(query: string) {
    return new RegExp(query.replace(/[^a-z0-9:]/igm ,""), "i").test([
      this.category,
      this.class,
      this.adverseEffects,
      this.contraindications,
      this.genericName,
      this.indications,
      this.tradeName
    ].join("").replace(/[, ]/igm, ""));
  }

  constructor(spec: Record<string, any>) {
    this.adverseEffects = prettifyArray(spec.adverseEffects ?? []);
    this.contraindications = prettifyArray(spec.contraindications ?? ["HS"]);
    this.indications = prettifyArray(spec.indications ?? []);

    this.doses = (spec.doses ?? []).map((it: Dose) => {
      it.indications = prettifyArray(it.indications ?? []);
      it.pretty = prettifyDose(it);
      return it;
    });

    this.description = spec.description;
    this.genericName = spec.genericName;
    this.category = !spec.category ? "" : `category:${spec.category}`;
    this.class = spec.class;
    this.duration = spec.duration;
    this.onset = spec.onset;
    this.tradeName = spec.tradeName;
    this.specialConsiderations = spec.specialConsiderations;

    this.id = `${spec.pk}|${spec.sk}`;
  }
}
