
import { IonIcon, IonItem, IonLabel } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DrugListItem",
  components: {
    IonIcon,
    IonItem,
    IonLabel
  },

  props: {
    drug: {}
  },

  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === "ios";
    }
  },

  data() {
    return {
      chevronForward
    }
  }
});
