import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { DynamoDBClient} from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, GetCommand, ScanCommand } from "@aws-sdk/lib-dynamodb";

const tableName = "emsrx";

const ddb = DynamoDBDocumentClient.from(new DynamoDBClient({
  region: "us-east-1",
  // endpoint: "http://localhost:8000",
  credentials: fromCognitoIdentityPool({
    identityPoolId: "us-east-1:a408c8a5-2bd6-4b4d-9c8d-8cf8fbc86a00",
    clientConfig: { region: "us-east-1" }
  })
}));

import { Drug } from "./Drug";

let drugs: Drug[] = [];

export const getDrugs = async () => {
  try {
    const res = await ddb.send(new ScanCommand({ TableName: tableName }));
    drugs = (res.Items || [])
      .map((it) => Drug.fromDynamoItem(it))
      .sort((x, y) => x.genericName < y.genericName ? -1 : 1);
    return drugs;
  } catch (err) {
    return [] as Drug[];
  }
}

export const getDrug = async (id: string) => {
  try {
    const [pk, sk] = id.split("|");
    const res = await ddb.send(new GetCommand({
      TableName: tableName,
      Key: { pk, sk }
    }));
    return Drug.fromDynamoItem(res.Item || {});
  } catch (err) {
    return {} as Drug;
  }
}
