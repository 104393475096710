
import {
  IonSearchbar,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar
} from "@ionic/vue";
import { defineComponent } from "vue";
import DrugListItem from "@/components/DrugListItem.vue";
import { Drug } from "@/data/Drug";
import { getDrugs } from "@/data/repository";

export default defineComponent({
  name: "Home",

  data() {
    return {
      drugs: [] as Drug[],
      search: ""
    }
  },

  created() {
    this.loadDrugs();
  },

  computed: {
    filteredDrugs(): Drug[] {
      return (this.drugs || []).filter((it) => it.search(this.search));
    },

    groups(): Record<string, Drug[]> {
      const result: Record<string, Drug[]> = {};
      const drugs = (this.drugs || [])
        .filter((it) => it.search(this.search));
      (drugs || [])
        .sort((x, y) => x.class.toLowerCase() <= y.class.toLowerCase() ? -1 : 1)
        .forEach((it) => {
          if (!(it.class in result)) {
            result[it.class] = [];
          }
          result[it.class].push(it);
          result[it.class] = result[it.class]
            .sort((x, y) => x.genericName <= y.genericName ? -1 : 1);
        });
      return result;
    }
  },

  methods: {
    refresh: (ev: CustomEvent) => setTimeout(() => ev.detail.complete(), 1000),

    loadDrugs() {
      getDrugs().then((it) => {
        this.drugs = it;
      });
    },
  },

  components: {
    IonSearchbar,
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    DrugListItem
  },
});
