import { createRouter, createWebHistory } from "@ionic/vue-router";
import Home from "../views/Home.vue";
import DrugDetail from "../views/DrugDetail.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: Home
    },
    {
      path: "/drug/:id",
      component: DrugDetail
    }
  ]
})

export default router
