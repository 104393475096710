export const capitalize = (it?: string) => !it ?
  "" :
  it[0].toUpperCase() + it.substring(1);

export const prettifyArray = (arr: string[]): string[] => arr
  .sort((x: string, y: string) => x.toLowerCase() <= y.toLowerCase() ? -1 : 1)
  .map(capitalize)
  .sort();

export const groupBy = (xs: any[], key: string) => xs.reduce((rv: any, it) => {
  const gk = Array.isArray(it[key]) ? JSON.stringify(it[key]) : it[key];
  (rv[gk] = rv[gk] ?? []).push(it);
  return rv;
}, {});
