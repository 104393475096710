import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a042ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date" }
const _hoisted_2 = {
  key: 0,
  class: "trade-name"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {"color":"red"} }
const _hoisted_6 = { class: "adverse-effect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.drug)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        routerLink: '/drug/' + _ctx.drug.id,
        detail: false,
        class: "list-item"
      }, {
        default: _withCtx(() => [
          (_ctx.isIos())
            ? (_openBlock(), _createBlock(_component_ion_label, {
                key: 0,
                slot: "end",
                class: "ion-text-wrap"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.chevronForward,
                      size: "small"
                    }, null, 8, ["icon"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createElementVNode("h2", null, [
                _createTextVNode(_toDisplayString(_ctx.drug.genericName) + " ", 1),
                (_ctx.drug.tradeName)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.drug.tradeName) + ") ", 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.drug.doses[0])
                ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.drug.doses[0].pretty), 1))
                : _createCommentVNode("", true),
              (_ctx.drug.onset && _ctx.drug.duration)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.drug.onset) + " / " + _toDisplayString(_ctx.drug.duration), 1))
                : _createCommentVNode("", true),
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.drug.pretty("contraindications")), 1),
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.drug.pretty("adverseEffects")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routerLink"]))
    : _createCommentVNode("", true)
}